import { createApp } from 'vue'
import 'bootstrap'
import VueCookies from 'vue3-cookies'
import App from './App.vue'
import router from './router'
import store from './store'
import VueDiff from 'vue-diff';
import 'vue-diff/dist/index.css';
import 'vue-diff/dist/index.css';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { Tooltip } from 'bootstrap';	
import axios from "axios";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'vis-network/styles/vis-network.css'

// HELPJUICE INTEGRATION
// window.helpjuice_account_url = 'https://help.aqfer.net';
// window.helpjuice_contact_us_url = 'https://help.aqfer.net/contact-us';
// window.helpjuiceSwiftyConfig = {
//     widgetPosition: 'bottomRight',
//     themeColor: '#152E4D',
//     linkColor: '#152E4D'
// }
// window.helpjuiceSwiftyUrlMap = {
// }

document.addEventListener('mouseover', handleTooltipShow)

// Function to handle tooltip show
function handleTooltipShow(event) {
  if (event.target.getAttribute('data-bs-toggle') === 'tooltip') {
    const tooltip = Tooltip.getOrCreateInstance(event.target);
    tooltip.show();
  }
}

// Add an event listener to clean up when the component is unmounted
document.addEventListener('mouseout', handleTooltipCleanup);

// Function to handle tooltip cleanup
function handleTooltipCleanup(event) {
  if (event.target.getAttribute('data-bs-toggle') === 'tooltip') {
    const tooltip = Tooltip.getInstance(event.target);
    if (tooltip) {
      tooltip.hide();
      tooltip.dispose(); // Properly destroy the tooltip instance
    }
  }
}

const timeoutInMS = 5000; // 5 Seconds ->  * 5 * 1000
var timeoutId=0;

function startTimer() { 
    // setTimeout call timer when user is inactive
    timeoutId = setTimeout(resetTimer, timeoutInMS);
}
//Reset the time if user is actively using app
function resetTimer() { 
    if (window.location.pathname == '/login')
        return;

    //Clearing old timer
    clearTimeout(timeoutId);
    const {$cookies} = router.app.config.globalProperties
    const expiry=$cookies.get('authExpireTime')
    if (expiry && expiry != '') {
        //If expiry time is less than current time then token is expired so logout
        if (new Date(expiry).getTime()<new Date().getTime()) {
            //Note : Auto refresh access-token can be added if user is actively using app
            store.dispatch('setRedirectPath', window.location.pathname)
            store.dispatch('logout');

        }
    }else if(store.state.environment != 'test') {
        store.dispatch('setRedirectPath', window.location.pathname)
        store.dispatch('logout');
    }
    //Start timer only if accessToken is Present else remove all listeners 
    const accessToken=$cookies.get('access-token')
    if(accessToken || store.state.environment == 'test' ){
        startTimer();
    }else{
        store.dispatch('setRedirectPath', window.location.pathname)
        store.dispatch('logout');
    }
}
//Globally Handle axios Error Response
axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      //Check where Default error handling is turned off
      if( error.config && error.config.errorHandle === false ) {
            return Promise.reject(error);
      }
      if (error.response){
        switch (error.response.status) {
          case 403:
            store.dispatch('whoAmICheck')
            break;
          // and so on..
        }
        return Promise.reject(error);
      }
    }
  );

// DASHKIT THEME JS FOR TESTING
// import theme from './theme/js/theme'

const options = {};
const app = createApp(App)
app.use(router)
app.use(VueCookies)
router.app = app
app.use(store)
app.use(VueDiff)
app.use(Toast);
app.mount('#app')
startTimer()