
import axios from 'axios';
import { DataSet } from "vis-network/standalone"

const store = {
    namespaced: true,
    state: {
        graphdsHost: "https://graphds.aut-prod.aqfer.com/v3/tenants/aqfer/entities/",
        dbdsHost: "https://dbds.aut-prod.aqfer.com/v3/tenants/aqfer/entities/",
        networkNodes: null,
        networkEdges: null
    },
    mutations: {
        setNetworkData(state, { nodes, edges }) {
            state.networkNodes = nodes
            state.networkEdges = edges
        }

    },
    actions: {

        async fetchAndConvertNetworkData({ commit, state, dispatch }, { personId, maxLevel = 1 }) {
            try {              

                // Parse the initial personId
                const [initialType, initialDomain, initialKey] = personId.split('~')

                let networkNodes = new Map() // Use Map to store unique nodes with their full data
                let relationships = [] // Store relationships between nodes

                let nodesToProcess = [{
                    id: personId,
                    level: 1,
                    type: initialType,
                    domain: initialDomain,
                    key: initialKey
                }]
                let processedIds = new Set()

                // Add initial node to networkNodes
                networkNodes.set(personId, {
                    id: personId,
                    type: initialType,
                    domain: initialDomain,
                    key: initialKey,
                    level: 1
                })

                // Process nodes iteratively using a queue
                while (nodesToProcess.length > 0 && nodesToProcess[0].level <= maxLevel) {
                    const currentNode = nodesToProcess.shift()

                    // Skip if we've already processed this node
                    if (processedIds.has(currentNode.id)) {
                        continue
                    }

                    processedIds.add(currentNode.id)

                    // Fetch edges for current node
                    const response = await dispatch('fetchEdges', currentNode.id);
                    
                    if(!Array.isArray(response) || (Array.isArray(response) && response.length === 0)){
                        continue
                    }
                    const nodeData = response;


                    // Find statid nodes and add them to processing queue if we haven't reached max level
                    if (currentNode.level <= maxLevel) {

                        nodeData.forEach(node => {
                            const nodeId = `${node.type}~${node.domain}~${node.key}`

                            // Add node to networkNodes if it doesn't exist
                            if (!networkNodes.has(nodeId)) {
                                networkNodes.set(nodeId, {
                                    id: nodeId,
                                    type: node.type,
                                    domain: node.domain,
                                    key: node.key,
                                    level: currentNode.level + 1
                                })

                                // Add relationship
                                relationships.push({
                                    from: currentNode.id,
                                    to: nodeId,
                                    fromType: currentNode.type,
                                    toType: node.type
                                })
                            }


                        })

                        let filteredNodes = [];

                        // If current node is person, find statid nodes
                        if (currentNode.type === 'prsn') {
                            filteredNodes = nodeData.filter(node => (node.type === 'statid' && !processedIds.has(node.id)))
                        }
                        // If current node is statid, find person nodes
                        else if (currentNode.type === 'statid') {
                            filteredNodes = nodeData.filter(node => node.type === 'prsn')
                        }                        

                        // Add new nodes to process
                        const newNodesToProcess = filteredNodes.map(node => ({
                            id: `${node.type}~${node.domain}~${node.key}`,
                            level: currentNode.level + 1,
                            type: node.type,
                            domain: node.domain,
                            key: node.key
                        }))

                        nodesToProcess = [...nodesToProcess, ...newNodesToProcess]
                    }
                }


                // Convert to vis format with complete network information
                const networkData = await dispatch('convertToVisFormat', {
                    nodes: Array.from(networkNodes.values()),
                    relationships: relationships
                })

                commit('setNetworkData', networkData)


                return networkData
            } catch (error) {
                console.log('Error fetching network data:', error)
                return null
            }
        },
        async convertToVisFormat({ commit }, { nodes, relationships }) {
            try {
                const visNodes = nodes.map(node => ({
                    id: node.id,
                    label: `${node.type}\n${node.key.slice(0, 8)}`,
                    level: node.level,
                    type: node.type,
                    domain: node.domain,
                    key: node.key,
                }));
                

                const visEdges = relationships.map(rel => ({
                    from: rel.from,
                    to: rel.to,
                    smooth: {
                        type: 'cubicBezier',
                        roundness: 0.2
                    }
                }));

                return {
                    nodes: visNodes,
                    edges: visEdges
                };
            } catch (error) {
                console.log('Error converting to vis format:', error);
                return {
                    nodes: [],
                    edges: []
                };
            }
        },

        async fetchEdges({ state ,dispatch}, key) {
            try {
                const response = await axios.get(`${state.graphdsHost}${key}/edges`)
                return response.data
            } catch (error) {
                console.log('error', error);
                const toastOptions = {
                    message: this.error || "Something went wrong when fetching edges for given key . Please contact support.",
                    type: "error",
                };
                await dispatch('addToast', toastOptions, { root: true });
                return null
            }
        },
        async fetchEntity({ state }, key) {
            try {
                const response = await axios.get(`${state.dbdsHost}${key}`)
                return response.data
            } catch (error) {
                console.log('error', error)
                return null
            }
        }
    },
    getters: {
        getNetworkData: (state) => {
            return {
                nodes: state.networkNodes || new DataSet([]),
                edges: state.networkEdges || new DataSet([])
            }
        }

    },

}

export default store;