<template>
    <div class="input-container">
        <div class="row">
            <div class="col-md-5">
                <div class="form-group">
                    <label for="personId" class="form-label">Enter Entity Key</label>
                    <input id="personId" v-model="personId" type="text" class="form-control"
                        :class="{ 'is-invalid': !isValidFormat && personId }" placeholder="prsn~domain~uuid"
                        @keyup.enter="handleEnter">
                    <div class="invalid-feedback position-absolute" v-if="!isValidFormat && personId">
                        Invalid format. Expected format: prsn~domain~uuid
                        (e.g., prsn~c900~7702f98b-a8d9-11ef-9451-0242ac110003)
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label for="nodeLevel" class="form-label">Node Level</label>
                    <input id="nodeLevel" v-model.number="nodeLevel" type="number" class="form-control" min="1"
                        placeholder="Enter Level (1-3)">
                </div>
            </div>

            <div class="col-md-2 align-self-center " v-if="personId !== ''">
                <button class="btn btn-primary w-100" @click="updateGraph" :disabled="!isValidFormat">
                    <i class="bi bi-search me-2"></i>
                    Update
                </button>
            </div>
        </div>
    </div>
    <div class="card">
        <div ref="networkContainer" class="network-container"></div>
    </div>
</template>

<script>
import { Network } from "vis-network/standalone"
import { mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {
            personId: '',
            nodeLevel: 1,
            network: null,
            options: {
                nodes: {
                    shape: 'dot',                    
                    font: {
                        size: 14
                    }
                },
                interaction: {
                    hover: true,
                    tooltipDelay: 300,
                },
                physics: {
                    enabled: true,
                    stabilization: true
                }
            }
        }
    },

    computed: {
        ...mapGetters('idr', ['getNetworkData']),

        networkData() {
            return this.getNetworkData;
        },
        isValidFormat() {
            // UUID regex pattern
            const uuidPattern = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

            // Complete pattern for personId
            const pattern = new RegExp(`^prsn~[a-z0-9]+~${uuidPattern}$`, 'i');

            return this.personId ? pattern.test(this.personId) : true;
        }
    },

    methods: {
        ...mapActions({
            fetchAndConvertNetworkData: "idr/fetchAndConvertNetworkData",
        }),

        handleEnter() {
            if (this.isValidFormat && this.personId) {
                this.updateGraph();
            }
        },

        async updateGraph() {
            try {
                await this.initNetwork()
            } catch (error) {
                console.error('Search failed:', error)
            }
        },

        async initNetwork() {
            try {
                await this.fetchAndConvertNetworkData({ personId: this.personId, maxLevel: this.nodeLevel })

                const container = this.$refs.networkContainer
                if (!container) {
                    console.error('Network container not found')
                    return
                }

                const data = this.networkData
                if (!data || !data.nodes || !data.edges) {
                    console.error('Invalid network data:', data)
                    return
                }

                console.log('Initializing network with data:', data)

                if (this.network) {
                    this.network.destroy()
                }

                this.network = new Network(container, data, this.options)

                this.network.on("hoverNode", function (params) {
                    const node = data.nodes.find(n => n.id === params.node);
                    if (node) {
                        const tooltip = document.createElement('div');
                        tooltip.innerHTML = `
                        <div style='
                            padding: 8px;
                            border-radius: 4px;
                            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                            font-size: 12px;
                        '>
                            <strong>Type: ${node.type}</strong> <br>
                            Domain: ${node.domain}<br>
                            key: ${node.key}
                        </div>
                    `;

                        // Position the tooltip near the mouse
                        tooltip.style.left = params.event.pageX + 5 + 'px';
                        tooltip.style.top = params.event.pageY + 5 + 'px';

                        // Remove any existing tooltips
                        const existingTooltip = document.querySelector('.vis-tooltip');
                        if (existingTooltip) {
                            existingTooltip.remove();
                        }

                        // Add tooltip class for easy removal
                        tooltip.classList.add('vis-tooltip');

                        // Add to document
                        document.body.appendChild(tooltip);
                    }
                });

                // Remove tooltip when mouse leaves node
                this.network.on("blurNode", function (params) {
                    const tooltip = document.querySelector('.vis-tooltip');
                    if (tooltip) {
                        tooltip.remove();
                    }
                });
            } catch (error) {
                console.log(error);
            }
        }
    },

    beforeUnmount() {
        if (this.network) {
            this.network.destroy()
        }
    }
}
</script>

<style>
.vis-tooltip {
    position: absolute;
    pointer-events: none;
    z-index: 1000;
    border: 1px solid #6E84A3 !important;
    background-color: #6E84A3 !important;
    color: #F9FBFD !important;
    visibility: visible !important;
}
.network-container {
    height: 600px;
    width: 100%;
    border: 1px solid #ddd;
    background-color: #ffffff;
}
</style>
