<template>
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">{{pretitle}}</h6>
            <!-- Title -->
            <h1 class="header-title" v-html="title"></h1>
            <!-- Secondary Title -->
            <h4  class="header-subtitle text-secondary" v-html="secondaryTitle"></h4>
          </div>
          <!-- Button -->
          <div class="col-auto">
            <span v-if='editable'>
                <span v-if='editMode'>
                    <button @click="deleteClick" class="btn btn-danger"> Delete </button>&nbsp;
                    <button :disabled="saveButtonState" @click="saveClick" class="btn btn-success"> Save </button>&nbsp;
                    <button @click="cancelClick" class="btn btn-secondary"> Cancel </button>&nbsp;
                </span>
                <span v-else>
                    <button @click="editClick" class="btn btn-info" :disabled="!editRoles.length || isAccessByUserRole(editRoles) ? false:true"> 
                    Edit
                    <i v-if="editRoles.length&&!isAccessByUserRole(editRoles)" class="fa fa-circle-exclamation" title="Your user do not have permission .Please contact support" data-bs-toggle="tooltip" data-bs-placement="right"></i>                                     
                   </button>&nbsp;
                </span>
            </span>
            <span v-if="customMode">
                <button @click="customClick" :class="customBtnClass" :disabled="!customModeRoles.length || isAccessByUserRole(customModeRoles) ? false:true"> 
                  {{customModeText}}
                    <i v-if="customModeRoles.length&&!isAccessByUserRole(customModeRoles)" class="fa fa-circle-exclamation" title="Your user do not have permission .Please contact support" data-bs-toggle="tooltip" data-bs-placement="right"></i>                                     
                </button>&nbsp;                
            </span>
            <button @click="closeClick" class="btn btn-secondary"> <span class="fa fa-x"></span> </button>
            
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
</template>

<script>
import formValidation from "../utils/formValidation";
export default {
    name: 'DetailHeader',
    props: {
        'pretitle': String,
        'title': String,
        'secondaryTitle': String,
        'editable': {
            type: Boolean,
            default: true
        },
        'editRoles':{
          type: Array,
          default: function () {
              return []
          }

        },
        'editMode': {
            type: Boolean,
            default: false
        },
        'customMode':{
            type: Boolean,
            default: false
        },
        'customModeRoles': {
            type: Array,
            default: function () {
              return []
            }
        },
        'customModeText': String,
        'fields': {
          type: Array,
          default: () => []
        },
        'modelValue': {
          tyep: Object,
          default: () => {}
        },
        'customBtnClass':{
            type: String,
            default: "btn btn-info"
        },
    },
    methods:{
      closeClick() {
        this.$emit('closeClick')
      },
      editClick() {
        this.$emit('editClick')
      },
      saveClick() {
        this.$emit('saveClick')
      },
      cancelClick() {
        this.$emit('cancelClick')
      },
      deleteClick() {
        this.$emit('deleteClick')
      },
      customClick() {
        this.$emit('customClick');
      },
      isAccessByUserRole(roles) {
        return this.$store.getters.userRoles.isAccessByRole(roles);
      } 

    },
    computed: {      
      saveButtonState() {            
            const { errors } = formValidation(); 
            let disabled = true;
            if(this.fields.length>0){
            for (let field of this.fields) {
                //Return true if field is required and not in modelValue or if validation is failed
                if (((typeof field.visible === "undefined" || field.visible) && field.validation && (field.validation.filter(e => e.type === 'required').length > 0) && (this.modelValue[field.name]===null || this.modelValue[field.name] === "" || typeof this.modelValue[field.name] === 'undefined') )||(errors[field.name] && !errors[field.name].passed)) {                
                    disabled = true;
                    break;
                }
                disabled = false;
            }
          }else{
            disabled=false;
          }
            return disabled;
        }
    },   
    emits: [
      'closeClick',
      'deleteClick',
      'editClick',
      'saveClick',
      'cancelClick',
      'customClick'
    ]
};
</script>

<style>
</style>