<template>
    <basic-header pretitle="Administration" title="Usage" :compact="true" />

    <div class="container-fluid">
        <tabs :tabs="tabItems">
            <template v-slot:current>

                <h2 class="mt-4">Current Month Usage ({{startDateLabel}} - {{endDateLabel}})</h2>
                
                <div v-if="loading" class="text-center mt-5">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                
                
                <div class="row mt-4">
                    <div class="col-md-6" v-for="(item) in entitlementUsage" :key="item.index">
                        
                        <div class="card">
                            <div class="card-header">
                                <h2 class="card-header-title">{{item.description}}</h2>
                            </div>
                            <div class="card-body">
                                <div class="row align-items-center gx-0">
                                    <div class="col">
                                        <h6 class="text-uppercase text-body-secondary mb-2">{{item.unit}}
                                        </h6>
                                        <span class="h2 mb-0">{{item.consumption.toFixed(1)}} / {{item.quantity}}</span>
                                        <div class="row align-items-center g-0">
                                            <div class="col-auto">
                                                <span class="h5 me-2 mb-0">{{item.consumption_percentage.toFixed(1)}}%</span>
                                            </div>
                                            <div class="col">
                                                <div class="progress progress-sm me-4">
                                                    <div class="progress-bar" role="progressbar" :style="{width: item.consumption_percentage+'%'}"
                                                        :aria-valuenow="item.consumption_percentage" aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <span class="h2 fa fa-coins text-body-secondary mb-0"></span>
                                    </div>
                                </div>
                            
                            <hr />
                            <div>
                                
                                <canvas :ref="`chart${item.name}`" :id="`chart${item.name}`" class="chart-canvas" width="843" height="400" style="
                                        display: block;
                                        box-sizing: border-box;
                                        height: 400px;
                                        width: 843px;
                                    ">
                            </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            </template>
            <template v-slot:historical>

                <br/>Coming Soon...
            </template>

        </tabs>


    </div>
</template>

<script>
import axios from "axios";
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    Utils
} from 'chart.js';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);

import dayjs from "dayjs";
import BasicHeader from "../../components/BasicHeader.vue";
import { isThrowStatement } from "typescript";
import Tabs from "../../components/Tabs.vue";
export default {
    components: { BasicHeader, Tabs },
    data() {
        return {
            loading: false,
            countsData: [],
            entitlements: [],
            entitlementUsage: [],
        }
    },
    computed: {
        startDateLabel() {
            return dayjs(this.startDate).format("MMM. D")
        },
        endDateLabel() {
            return dayjs(this.endDate).format("MMM. D, YYYY") 
        },
        tabItems() {
            return [
                {
                    name: "Current",
                    id: "id1",
                    slot: "current"
                },
                {
                    name: "Historical",
                    id: "id2",
                    slot: "historical"
                },
                {
                    name: "aUT",
                    id: "id3",
                    slot: "aut",
                    hide:  true
                },
                {
                    name: "aUT Legacy",
                    id: "id1",
                    slot: "aut-unpooled",
                    hide:  true
                },

            ]

        }

    },
    methods: {
        async initChartData() {
            //this.entilements = await this.getEntitlements()
            await this.getEntitlementUsage()
        },
        async getEntitlements() {
            return axios.get(
                `${this.$store.state.clientMgmtUrl}/v1/clients/${this.$store.state.currentClient.cid}/product_entitlement/dashboard`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            );
        },
        async getEntitlementUsage() {

            this.loading = true;
            var entitlementUsage = []

            const subscriptions = await axios.get(
                `${this.$store.state.clientMgmtUrl}/v1/clients/${this.$store.state.currentClient.cid}/products`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            );

            console.log('subscriptions', subscriptions)

            let productEntitlements = []
            for (const sub of subscriptions.data) {
                let prod_ents = await axios.get(
                    `${this.$store.state.clientMgmtUrl}/v1/product_entitlements?product=${sub.product_id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`,
                        },
                    }
                );

                //adjust quantity of each based on the subscription, then add it to the overall entitlements
                prod_ents = prod_ents.data
                for (let pe of prod_ents) {
                    pe.quantity *= sub.quantity
                    const existingPE = productEntitlements.find(item => item.name === pe.name);
                    if (existingPE) {
                        existingPE.quantity += pe.quantity
                    } else {
                        productEntitlements.push(pe)
                    }   
                }
            }

            console.log("base entitlements", productEntitlements) 

            let allEntitlements = await axios.get(
                `${this.$store.state.clientMgmtUrl}/v1/entitlements`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            );
            allEntitlements = allEntitlements.data

            //Temporary - update all entitlments with metrics keys
            for (const ent of allEntitlements) {
                ent.metrics_key = ent.name
                if (ent.name == "aMDPIngestRecords")
                    ent.metrics_key = "aMDPRecords"
                if (ent.name == "aMDPDistributionRecords")
                    ent.metrics_key = "aMDPDistributeRecords"
                if (ent.name == "aMDPUnsortedCIR")
                    ent.metrics_key = "aMDPCollateRecords"
            }

            console.log("all entitlements", allEntitlements)

            const startDate = dayjs().startOf('month').format('YYYY-MM-DD');
            const endDate = dayjs().endOf('month').format('YYYY-MM-DD');
            const amdpUsage = await axios.get(
                `${this.$store.state.countsUrl}/v2/cids/${this.$store.state.currentClient.cid}/products/amdp/usage?start_date=${startDate}&end_date=${endDate}&aggregate=true`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            );
            const amdpUsageAggregates = amdpUsage.data.amdp?.data

            const aioUsage = await axios.get(
                `${this.$store.state.countsUrl}/v2/cids/${this.$store.state.currentClient.cid}/products/aio/usage?start_date=${startDate}&end_date=${endDate}&aggregate=true`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            );
            const aioUsageAggregates = aioUsage.data.aio?.data

            const autUsage = await axios.get(
                `${this.$store.state.countsUrl}/v2/cids/${this.$store.state.currentClient.cid}/products/aut/usage?start_date=${startDate}&end_date=${endDate}&aggregate=true`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            );
            const autUsageAggregates = autUsage.data.aut?.data
        
            const usageAggregates = [
                ...(amdpUsageAggregates || []),
                ...(aioUsageAggregates || []),
                ...(autUsageAggregates || [])
            ];

            for(const agg of usageAggregates) {
                const ent = allEntitlements.find(item => (item.metrics_key === agg[0]));
                if(ent) {
                    if (ent.base_entitlement_id) {

                        var unitMultiplier = 1;
                        switch(ent.unit){
                            case "billion": unitMultiplier = 1000000000; break;
                            case "million": unitMultiplier = 1000000; break;
                            case "TB": unitMultiplier = 1000; break;
                            case "GB": unitMultiplier = 1; break;
                        }

                        const ceiling = ent.quantity * unitMultiplier

                        ent.usage = agg[1] 
                        ent.consumption = ent.usage * ent.base_qty_consumed / ceiling

                        //SEt or update base entitlement info for charts
                        var baseEntitlement = this.entitlementUsage.find(item => item.entitlement_id === ent.base_entitlement_id);
                        if (!baseEntitlement) {
                            const entInfo = allEntitlements.find(item => item.entitlement_id === ent.base_entitlement_id);
                            baseEntitlement = productEntitlements.find(item => item.entitlement_id === ent.base_entitlement_id);
                            baseEntitlement = { ...entInfo, ...baseEntitlement };
                            baseEntitlement.sub_entitlements = [];
                            baseEntitlement.consumption = 0
                            this.entitlementUsage.push(baseEntitlement)
                        }

                        // check incldued with base entitlement
                        if (ent.qty_included_w_base) {
                            ent.consumption = ent.consumption - (ent.qty_included_w_base * baseEntitlement.quantity)
                            if(ent.consumption < 0)
                                ent.consumption = 0;
                        }
                        
                        baseEntitlement.sub_entitlements.push(ent)
                        baseEntitlement.consumption += ent.consumption
                        baseEntitlement.consumption_percentage = baseEntitlement.consumption / baseEntitlement.quantity * 100
                    }
                }
            }


            console.log("Final Usage Info", this.entitlementUsage)
            this.drawCharts()

            this.loading = false;
            
        },

        drawCharts() {

            const horizontalLine = {
                id: 'horizontalLine',
                beforeDraw(chart, args, options) {
                    const ctx = chart.ctx;
                    const yScale = chart.scales['y'];
                    const yValue = options.limit; 
                    
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(chart.chartArea.left, yScale.getPixelForValue(yValue));
                    ctx.lineTo(chart.chartArea.right, yScale.getPixelForValue(yValue));
                    ctx.strokeStyle = options.borderColor;
                    ctx.lineWidth = options.borderWidth;
                    ctx.setLineDash(options.borderDash || []);
                    ctx.lineDashOffset = options.borderDashOffset;
                    ctx.stroke();
                    ctx.restore();
                }
            };

            if(this.entitlementUsage) {
                for (const usageData of this.entitlementUsage) {
                    let datasets = []
                    for (const subEnt of usageData.sub_entitlements) {
                        datasets.push({
                            label: subEnt.name,
                            data: [subEnt.consumption]
                        })
                    }
                    
                    //Entitlement POOLED CHART
                    const pooledChart = this.$refs['chart'+usageData.name]
                    const pooledLabels = ['Usage']
                    const pooledData = {
                        labels: pooledLabels,
                        datasets: datasets
                    };

                    const pooledConfig = {
                        type: 'bar',
                        data: pooledData,
                        options: {
                            plugins: {
                                title: {
                                    display: false,
                                    text: 'Usage'
                                },
                                horizontalLine: {
                                    borderColor: 'red',
                                    borderWidth: 2,
                                    borderDash: [5, 5],
                                    borderDashOffset: 2,
                                    limit: usageData.quantity,
                                }
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    stacked: true,
                                },
                                y: {
                                    stacked: true
                                }
                            }
                        },
                        plugins: [horizontalLine]
                    };

                    if (pooledChart) {
                        new Chart(pooledChart, pooledConfig);
                    }
                }

            }

        },

    },
    async mounted() {
        await this.initChartData();
        this.drawCharts()
    }
};
</script>

<style></style>