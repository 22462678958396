<template>
    <basic-header pretitle="Administration" title="Usage" :compact="true" />
    <div class="container-fluid">
        <tabs :tabs="tabItems">
            <template v-slot:monthly>      
                <div v-if="loading" class="text-center mt-5">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-else class="row g-3 align-items-center mt-3">
                    <div class="col-auto">
                        <InputField colField="mb-0"  class="h2" :editable="true" label="Usage Data for Month" :options="monthOptions"
                            type="select" :modelValue="usageMonth" @change="usageMonthChange($event)" />
                    </div>  
                </div>
                <!-- <h2  class="mt-4">Current Month Usage ({{startDateLabel}} - {{endDateLabel}})</h2> -->
                <EntitlementUsageCharts v-if="type=='Monthly'" :chartValue="entitlementUsage" :type="type"/>
            </template>
            <template v-slot:historical>
                <div v-if="loading" class="text-center mt-5">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <HistoricalEntitlementUsageCharts v-if="type=='Historical'" :monthlyData="historicalEntitlementUsage" :type="type"/>
            </template>
        </tabs>
    </div>
</template>

<script>
import axios from "axios";

import dayjs from "dayjs";
import BasicHeader from "../../components/BasicHeader.vue";
import InputField from "../../components/InputField.vue";
import Tabs from "../../components/Tabs.vue";
import { mapState } from "vuex";
import { generateLastSixMonthOptions , getFirstDayOfMonth, getLastDayOfMonth} from '../../utils/commonFunction';
import EntitlementUsageCharts from "../../components/admin/EntitlementUsageCharts.vue";
import HistoricalEntitlementUsageCharts from "../../components/admin/HistoricalEntitlementUsageCharts.vue";
export default {
    components: { BasicHeader, InputField, Tabs, EntitlementUsageCharts, HistoricalEntitlementUsageCharts },
    data() {
        return {
            loading: false,
            countsData: [],
            entitlements: [],
            entitlementUsage: [],
            historicalEntitlementUsage: [],
            dateConfig: {
                wrap: true,
                dateFormat: 'Y-m-d'
            },
            startDate: null,
            endDate: null,
            snapType: 'Monthly',
            monthOptions:generateLastSixMonthOptions(false)      
        }
    },    
    computed: {      
        ...mapState({            
            usageMonth: state => state.admin.entitlementUsageMonth,
        }),
        startDateLabel() {
            return dayjs(this.startDate).format("MMM. D")
        },
        endDateLabel() {
            return dayjs(this.endDate).format("MMM. D, YYYY") 
        },
        tabItems() {
            var self= this
            return [
                {
                    name: "Monthly",
                    id: "id1",
                    slot: "monthly",
                    onClick: function(){
                        self.getCurrentEntitlementUsage()
                    }
                },
                {
                    name: "Historical",
                    id: "id2",
                    slot: "historical",
                    onClick: function(){
                        self.getHistoricalEntitlementUsage()
                    }
                },
                {
                    name: "aUT",
                    id: "id3",
                    slot: "aut",
                    hide:  true
                },
                {
                    name: "aUT Legacy",
                    id: "id1",
                    slot: "aut-unpooled",
                    hide:  true
                },

            ]

        },
        type(){
            return this.$store.state.activeTab || 'Monthly'
        }
    },
    methods: {
        async usageMonthChange(month) {
            this.$store.dispatch('admin/setEntitlementUsageMonth',month);            
            this.startDate = getFirstDayOfMonth(month);
            this.endDate = getLastDayOfMonth(month);
            await this.getCurrentEntitlementUsage();            
        },    
        async initChartData() {
            if(this.type =='Monthly')
                await this.getCurrentEntitlementUsage()
            else
                await this.getHistoricalEntitlementUsage()
        },
        async fetchEntitlementUsage(startDate, endDate) {
            var entitlementUsage = []
            entitlementUsage = await axios.get(
                `${this.$store.state.clientMgmtUrl}/v1/clients/${this.$store.state.currentClient.cid}/product_entitlement/dashboard?start_date=${startDate}&end_date=${endDate}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            );
            return entitlementUsage.data ? entitlementUsage.data:[]
            
        },
        async getCurrentEntitlementUsage(dateChange=false) {            
            
            this.loading = true;
            this.entitlementUsage = [];
            this.entitlementUsage = await this.fetchEntitlementUsage(this.startDate, this.endDate)

            console.log("Final Usage Info", this.entitlementUsage)
            
            this.loading = false;
            this.snapType= this.type
        },

        async getHistoricalEntitlementUsage(dateChange=false) {
            this.loading = true;
            this.historicalEntitlementUsage = [];
            let historicalEntitlementUsage = [];
            
            const monthSpan = 6
            for (let i=monthSpan-1; i>-1; i--){
                let dateObj = dayjs().subtract(i, "month");
                let startDate = dateObj.startOf('month').format('YYYY-MM-DD');
                let endDate = dateObj.endOf('month').format('YYYY-MM-DD');
                let month = dateObj.format('MMMM');

                historicalEntitlementUsage[month] = await this.fetchEntitlementUsage(startDate, endDate)
            }


            this.historicalEntitlementUsage = historicalEntitlementUsage

            console.log("Final Usage Info", this.historicalEntitlementUsage)
            
            this.loading = false;
            this.snapType= this.type
        },
    },
    beforeMount() {
        const month =  this.usageMonth;
        this.startDate = getFirstDayOfMonth(month);
        this.endDate = getLastDayOfMonth(month);
    },
    async mounted() {
        this.$store.dispatch('setActiveTab', "Monthly")
        await this.initChartData();
        this.snapType= this.type;
    }
};
</script>

<style></style>