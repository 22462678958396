<template>
	<div class="form-group inline-field row align-items-center sub-input-group">
		<label class="col-sm-3 col-form-label"> Config Name <span  :class="['fa fa-circle-info text-muted']" title="Name of privacy configuration. choose none otherwise" data-bs-toggle="tooltip"  data-bs-placement="right"></span></label>
		<div class="col-sm-7">
			<select
				class="form-select"
				v-model="inputVal['configName']"
				:disabled="!editable"
				:readonly="!editable"
			>
				<option :value=null>NONE</option>
				<option
					v-for="option in privacyConfigOptions"
					:key="option.index"
					:value="option.value"
				>
					{{ option.label }}
				</option>
			</select>
		</div>
		<div class="col-sm-2 inline-field align-items-center sub-input-group collapsed"  data-bs-toggle="collapse" data-bs-target="#usMultiStatePrivacyView" aria-expanded="false" aria-controls="usMultiStatePrivacyView" @click="onToggle">
			<label class="float-right text-primary collapsed">
			<span>
				{{this.showMore?"Show More":"Show Less"}}
			</span>
		</label>
		</div>
	</div>
	<div v-for="(privacyStatus,index) in UsPrivacyStatusList" :key="index" class="row inline-field align-items-center sub-input-group accordion-collapse collapse" id="usMultiStatePrivacyView">
		<label class="col-md-3 col-form-label">{{ privacyStatus.key.toUpperCase()}} <i class="fa fa-circle-info text-muted" :title="privacyStatus.tooltip" data-bs-toggle="tooltip" data-bs-placement="right"></i></label>
		<div class="col-md-8">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" :id="`ENABLED-${privacyStatus.key}`" type="radio" v-model="inputVal[privacyStatus.key]"  :name="`${privacyStatus.key}-ENABLED`" value="ENABLED" :disabled="!editable" :readonly="!editable">
                    <label class="form-check-label" >ENABLED</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" :id="`BLOCKED-${privacyStatus.key}`" type="radio" v-model="inputVal[privacyStatus.key]"  :name="`${privacyStatus.key}-BLOCKED`" value="BLOCKED" :disabled="!editable" :readonly="!editable">
                    <label class="form-check-label" >BLOCKED</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" :id="`DISABLED-${privacyStatus.key}`" type="radio" v-model="inputVal[privacyStatus.key]"  :name="`${privacyStatus.key}-DISABLED`" value="DISABLED" :disabled="!editable" :readonly="!editable">
                    <label class="form-check-label" >DISABLED</label>
                </div>  
		</div>
	</div>	
</template>

<script>
	import { mapState } from "vuex";
	export default {
		data(){
			return {
				UsPrivacyStatusList:[
					{key:"usnat",tooltip:"USNAT is applicable when gpp_sid=7"},
					{key:"usca",tooltip:"USCA is applicable when gpp_sid=8"},
					{key:"usva", tooltip:"USVA is applicable when gpp_sid=9"},
					{key:"usco",tooltip:"USCO is applicable when gpp_sid=10"},
					{key:"usut", tooltip:"USUT is applicable when gpp_sid=11"},
					{key:"usct", tooltip:"USCT is applicable when gpp_sid=12"},
					{key:"usfl", tooltip:"USFL is applicable when gpp_sid=13"},
					{key:"usmt", tooltip:"USMT is applicable when gpp_sid=14"},
					{key:"usor", tooltip:"USOR is applicable when gpp_sid=15"},
					{key:"ustx", tooltip:"USTX is applicable when gpp_sid=16"},
					{key:"usde", tooltip:"USDE is applicable when gpp_sid=17"},
					{key:"usia", tooltip:"USIA is applicable when gpp_sid=18"},
					{key:"usne", tooltip:"USNE is applicable when gpp_sid=19"},
					{key:"usnh", tooltip:"USNH is applicable when gpp_sid=20"},
					{key:"usnj", tooltip:"USNJ is applicable when gpp_sid=21"},
					{key:"ustn", tooltip:"USTN is applicable when gpp_sid=22"},
				],
				showMore: true
			}

		},
		computed: {
			inputVal() {
				return this.modelValue ? this.modelValue : {};
			},
			...mapState({
				privacyConfig: (state) => state.aut.privacyConfig,
			}),
			privacyConfigOptions() {
				if (this.privacyConfig?.length) {
					return this.privacyConfig.map((config) => {
						return { label: config.name, value: config.name };
					});
				}
				return [];
			},
		},
		props: {
			modelValue: {
				type: Object,
				default: () => {},
			},
			editable: {
				type: Boolean,
				default: true,
			},
			visible: {
				type: Boolean,
				default: true,
			},
		},
		methods:{
			onToggle(){
				this.showMore=!this.showMore;
			}
		},

		emits: ["update:modelValue", "change"]		
	};
</script>

<style></style>
